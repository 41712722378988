<template>
  <v-form ref="formSearch" class="py-4" @submit.prevent="searchTickets">
    <v-container>
      <v-row>
        <v-col cols="12" lg="12" sm="12" class="pb-0 pt-1">
          <div class="title-card readable">{{ getTitle[lang] }}</div>
          <p class="subtitle-card mb-1">{{ getSubtitle[lang] }}</p>
        </v-col>
        <!-- DOCUMENT -->
        <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
          <v-row>
            <v-col cols="12" lg="12" sm="12">
              <v-radio-group v-model="payload.iTipoDocumento" row>
                <v-radio
                  class="readable"
                  :label="labels.iTipoDocumento.firstOpt[lang]"
                  :value="1"
                ></v-radio>
                <v-radio
                  class="readable"
                  :label="labels.iTipoDocumento.secondOpt[lang]"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
              <v-text-field
                :label="labels.vNumeroDocumento[lang]"
                v-mask="documentMask"
                :rounded="rounded"
                :color="color"
                class="readable"
                :dark="dark"
                :background-color="bgColor"
                :rules="[...required, ...rules.document]"
                :disabled="disabled"
                :hide-details="details"
                :outlined="outlined"
                :filled="filled"
                :dense="dense"
                :autocomplete="autocomplete"
                v-model="payload.vNumeroDocumento"
                :solo="solo"
                :class="{ [roundedFieldclass]: roundedFieldclass }"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <!-- FECHA -->
        <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
          <v-row>
            <v-col cols="12" lg="12" sm="12">
              <v-radio-group v-model="payload.iTipoFecha" row>
                <v-radio
                  class="readable"
                  :label="labels.iTipoFecha.firstOpt[lang]"
                  :value="1"
                ></v-radio>
                <v-radio
                  class="readable"
                  :label="labels.iTipoFecha.secondOpt[lang]"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
              <v-text-field
                :label="labels.vFecha[lang]"
                type="date"
                :rounded="rounded"
                :color="color"
                class="readable"
                :dark="dark"
                :background-color="bgColor"
                :rules="[...required]"
                :disabled="disabled"
                :hide-details="details"
                :outlined="outlined"
                :filled="filled"
                :dense="dense"
                :autocomplete="autocomplete"
                v-model="payload.vFecha"
                :solo="solo"
                :class="{ [roundedFieldclass]: roundedFieldclass }"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class="pb-1 pt-0 d-flex jutify-content-center"
        >
          <v-btn
            class="mx-auto readable"
            @click="searchTickets"
            :loading="loading"
            :disabled="loading"
            depressed
            color="tertiary"
            dark
            :aria-label="labels.buttonSubmit[lang]"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
            :style="{ minWidth: '180px' }"
          >
            <span class="mr-2">{{ labels.buttonSubmit[lang] }}</span>
            <v-icon>search</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <Recaptcha ref="recaptcha" @verify="submit"></Recaptcha>
    </v-container>
  </v-form>
</template>

<script>
import baseConfig from "@/components/forms/baseConfig";
import Recaptcha from "./Recaptcha.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Recaptcha,
  },
  data() {
    return {
      ...baseConfig,
      required: [this.validateRequired],
      getTitle: {
        en: "Search Form",
        es: "Formulario de búsqueda",
      },
      getSubtitle: {
        en:
          "Fill in your document number and the date of purchase/registration to find the details of your purchased ticket",
        es:
          "Completa tu número de documento y la fecha de compra/registro para encontrar los detalles de tu entrada adquirida",
      },
      labels: {
        iTipoDocumento: {
          en: "Document type",
          es: "Tipo de documento",
          firstOpt: {
            en: "DNI",
            es: "DNI",
          },
          secondOpt: {
            en: "Pasaport / IC",
            es: "Pasaporte / C.E",
          },
        },
        iTipoFecha: {
          en: "Date type",
          es: "Tipo de Fecha",
          firstOpt: {
            en: "Visit date",
            es: "Fecha de visita",
          },
          secondOpt: {
            en: "Date of issue",
            es: "Fecha de emisión",
          },
        },
        vNumeroDocumento: {
          en: "Document number",
          es: "Número de documento",
        },
        vFecha: {
          en: "Date of purchase or registration",
          es: "Fecha de compra o registro",
        },
        buttonSubmit: {
          en: "Search",
          es: "Buscar",
        },
        errorMessages: {
          dni: {
            length: 8,
            en: "Incorrect DNI",
            es: "DNI inválido",
          },
          passport: {
            length: 8,
            en: "Incorrect Pasaport / IC",
            es: "Pasaporte / C.E incorrecto",
          },
        },
        requiredLabel: {
          en: "Required field",
          es: "Campo requerido",
        },
      },
      rules: {
        document: [this.validateDocumentLength],
      },
      documentMask: "########",
      loading: false,
      payload: {
        iTipoDocumento: 1,
        vNumeroDocumento: "",
        iTipoFecha: 1,
        vFecha: "",
      },
    };
  },
  watch: {
    "payload.iTipoDocumento": function(val) {
      this.documentMask = val == 1 ? "########" : "############";
      this.payload.vNumeroDocumento = "";
    },
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
  },
  methods: {
    ...mapActions({
      validateCaptcha: "validateCaptcha",
      getShoppingsListByDocumentAndDate: "getShoppingsListByDocumentAndDate",
      generateSnackbarError: "generateSnackbarError",
    }),
    validateRequired(v) {
      if (!v) return this.labels.requiredLabel[this.lang];
      return true;
    },
    validateDocumentLength(v) {
      if (this.payload.iTipoDocumento == 1) {
        return (
          v.length >= this.labels.errorMessages.dni.length ||
          this.labels.errorMessages.dni[this.lang]
        );
      } else {
        return (
          v.length >= this.labels.errorMessages.passport.length ||
          this.labels.errorMessages.passport[this.lang]
        );
      }
    },
    formatDateToISO(date) {
      const arr = date.split("-");
      return `${arr[2]}/${arr[1]}/${arr[0]}`;
    },
    async submit(response) {
      this.loading = true;
      // validate captcha
      const validated = await this.validateCaptcha({
        token: response,
        defaultScore: 0.4,
      });
      this.loading = false;
      if (!validated) {
        this.generateSnackbarError({
          en:
            "A malicious attempt is perceived in the use of the system, your request does not proceed.",
          es:
            "Se persive un intento mal intencionado en el sistema, no procede la solicitud.",
        });
        return;
      }
      this.loading = true;
      const results = await this.getShoppingsListByDocumentAndDate({
        ...this.payload,
        vFecha: this.formatDateToISO(this.payload.vFecha),
      });
      this.loading = false;
      if (results.length == 0) {
        this.generateSnackbarError({
          en: `No matching results were found on the date ${this.payload.vFecha}`,
          es: `No se encontraron resultados coincididos en la fecha ${this.payload.vFecha}`,
        });
      }
      this.$emit("result", results);
    },
    // execute the recaptcha widget
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    validateForm() {
      return this.$refs.formSearch.validate();
    },
    async searchTickets() {
      if (!this.validateForm()) return;
      this.loading = true;
      this.executeRecaptcha();
    },
  },
};
</script>

<style lang="scss"></style>
