<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-content-center">
        <v-col cols="12" lg="8" md="10" sm="12">
          <v-card elevation="0">
            <FormSearch @result="results = $event"></FormSearch>
          </v-card>
        </v-col>
      </v-row>
      <!-- RESULTS -->
      <v-row>
        <v-col v-if="results && results.length > 0" cols="12" lg="12" sm="12">
          <div class="title-card">{{ titleResults[lang] }}</div>
        </v-col>
        <v-col
          v-for="(item, idx) in results"
          :key="item.IDCOMPRA + idx"
          cols="12"
          lg="12"
          sm="12"
        >
          <CardTicket
            :ticket="item"
            :redirectTo="{
              name: 'TicketDetail',
              params: { purchaseId: item.IDCOMPRA, parkId: item.IDPARQUE },
              query: { visitDate: item.FECHAVISITA },
            }"
          ></CardTicket>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormSearch from "./components/FormSearch.vue";
import CardTicket from "./components/CardTicket.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    FormSearch,
    CardTicket,
  },
  data() {
    return {
      titleResults: {
        en: "Search results",
        es: "Resultados de la búsqueda",
      },
      results: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      accTools: "getAccesibilityTool",
    }),
  },
  watch: {
    results() {
       this.accTools.restart(this.lang)
    },
  },
  methods: {},
  mounted() {
    this.accTools.restart(this.lang)
  }
};
</script>

<style>
</style>